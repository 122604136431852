<template>
  <div class="bg-f">
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <div class="accout-content clearfix" style="display:flex">
          <!-- 左边 -->
          <user-left mark="management" :userId="userId" />
          <!-- 右边 -->
          <a-card :bordered="false" class="card-right" style="width:100%">
            <a-radio-group
              :value="meetingType"
              @change="handleMeetingType"
              v-if="companyType == 1"
            >
              <a-radio-button :value="1">
                发给我的
              </a-radio-button>
              <a-radio-button :value="2">
                我发起的
              </a-radio-button>
            </a-radio-group>
            <a-table
              ref="table"
              :row-key="
                (record) => {
                  return record.meetingId;
                }
              "
              :columns="columns"
              :data-source="list"
              :pagination="pagination"
              @change="onChange1"
            >
              <span slot="accept" slot-scope="text, record">
                <template v-if="record.status != 2 && record.acceptStatus == 0">
                  <a @click="handleAccept(record.meetingId, 1)">{{
                    $t("user.meeting.accept")
                  }}</a>
                  |
                  <a @click="handleAccept(record.meetingId, -1)">{{
                    $t("user.meeting.decline")
                  }}</a>
                </template>
                <template v-else>
                  {{ record.acceptStatusText }}
                </template>
              </span>
              <span slot="check" slot-scope="text, record">
                <router-link
                  :to="{
                    name: 'PurchaserInfo',
                    params: { id: record.purchaserId, userId: userId },
                  }"
                  >{{ $t("user.check") }}</router-link
                >
              </span>
              <span slot="chat" slot-scope="text, record">
                <router-link
                  :to="{
                    name: 'Talking',
                    params: { adminId: record.purchaserUserId },
                  }"
                  >{{ $t("user.send-message") }}</router-link
                >
              </span>
              <span slot="start" slot-scope="text, record">
                <a v-if="record.status != 2 && record.acceptStatus == 1">{{
                  $t("user.meeting.startup")
                }}</a>
                <a style="color:gray" v-else>{{
                  $t("user.meeting.startup")
                }}</a>
              </span>
            </a-table>
          </a-card>
        </div>
      </div>
    </div>
    <!-- 公共尾部 -->
    <Footer></Footer>
  </div>
</template>

<style>
.ant-card-body {
  text-align: left;
}
.ant-table-body {
  margin-top: 10px;
}
</style>

<script>
import UserHeader from "./components/Header";
import UserLeft from "./components/Left";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Editor from "@/components/Editor/TinyMceEditor";

export default {
  name: "UsersManagement",
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header,
  },
  data() {
    return {
      userId: this.$route.params.userId,
      columns: [],
      list: [],
      page: 1,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        pageSizeOptions: ["10", "20", "30", "50", "100"],
        showTotal: (total, range) => {
          return (
            <div>
              共 <span>{total}</span> 条
            </div>
          );
        },
        onShowSizeChange: (current, pageSize) => {
          this.pageSize = pageSize;
        },
      },
      meetingType: "", // 1:发给我的 2:我发起的
    };
  },
  computed: {
    companyType() {
      return localStorage.categoryId || ""; // 1:参展商 2:采购商
    },
  },
  mounted() {
    if (this.companyType == 1) {
      this.meetingType = 1;
    } else {
      this.meetingType = 2;
    }
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    // 改变每页数量时更新显示
    onChange1(pagination) {
      this.pagination.defaultCurrent = pagination.current;
      this.pagination.defaultPageSize = pagination.pageSize;
      this.getList();
    },
    // 视频会议列表
    getList() {
      const params = {
        page: this.pagination.defaultCurrent,
        pagesize: this.pagination.defaultPageSize,
        meetingType: this.meetingType,
      };
      this.$common
        .fetchList("/user/meeting/lists", params)
        .then((data) => {
          if (data.code === 200) {
            const result = data.data;
            this.list = result.lists;
            console.log(this.list);
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
    // 改变发起人
    handleMeetingType(e) {
      this.meetingType = e.target.value;
      this.getList();
    },
    // 是否接受邀请
    handleAccept(meetingId, status) {
      if (meetingId && status) {
        var message = this.$t('user.meeting.handle-accept-status')
        if (status == -1) {
          message = this.$t('user.meeting.handle-decline-status')
        }
        const _this = this
        this.$confirm({
          title: message,
          onOk() {
            _this.$common
              .handleGet("user/meeting/accept/status/" + meetingId, {
                acceptStatus: status,
              })
              .then((data) => {
                if (data.code === 200) {
                  _this.$message.success(data.msg);
                  _this.getList();
                } else {
                  _this.$message.error(data.msg);
                }
              });
          },
          onCancel() {
            console.log("Cancel");
          },
          class: "test",
        });
      }
    },
  },
  watch: {
    meetingType(val) {
      this.list = [];
      var base = [
        {
          title: this.$t("user.number"),
          dataIndex: "meetingId",
        },
        {
          title: this.$t("user.meeting.type"),
          dataIndex: "typeText",
        },
        {
          title: this.$t("user.meeting.addr"),
          dataIndex: "meetingUrl",
        },
        {
          title: this.$t("user.meeting.startTime"),
          dataIndex: "startTime",
        },
        {
          title: this.$t("user.meeting.endTime"),
          dataIndex: "endTime",
        },
        {
          title: this.$t("user.meeting.status"),
          dataIndex: "statusText",
        },
        {
          title: this.$t("user.meeting.supplier-company-name"),
          dataIndex: "exhibitorName",
        },
      ];
      // 1:发给我的 2:我发起的
      if (val == 1) {
        this.columns = [
          ...base,
          {
            title: this.$t("user.meeting.isaccept"),
            scopedSlots: { customRender: "accept" },
          },
          {
            title: this.$t("user.meeting.purchaser"),
            scopedSlots: { customRender: "check" },
          },
          {
            title: this.$t("user.meeting.chat"),
            scopedSlots: { customRender: "chat" },
          },
          {
            title: this.$t("user.meeting.start"),
            scopedSlots: { customRender: "start" },
          },
        ];
      } else if (val == 2) {
        // 采购商
        this.columns = [
          ...base,
          {
            title: this.$t("user.meeting.isaccept"),
            dataIndex: "acceptStatusText",
          },
          {
            title: this.$t("user.meeting.start"),
            scopedSlots: { customRender: "start" },
          },
        ];
      } else {
        this.columns = base;
      }
    },
  },
};
</script>
