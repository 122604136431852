var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-f"},[_c('Header'),_c('user-header'),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"accout-content clearfix",staticStyle:{"display":"flex"}},[_c('user-left',{attrs:{"mark":"management","userId":_vm.userId}}),_c('a-card',{staticClass:"card-right",staticStyle:{"width":"100%"},attrs:{"bordered":false}},[(_vm.companyType == 1)?_c('a-radio-group',{attrs:{"value":_vm.meetingType},on:{"change":_vm.handleMeetingType}},[_c('a-radio-button',{attrs:{"value":1}},[_vm._v(" 发给我的 ")]),_c('a-radio-button',{attrs:{"value":2}},[_vm._v(" 我发起的 ")])],1):_vm._e(),_c('a-table',{ref:"table",attrs:{"row-key":function (record) {
                return record.meetingId;
              },"columns":_vm.columns,"data-source":_vm.list,"pagination":_vm.pagination},on:{"change":_vm.onChange1},scopedSlots:_vm._u([{key:"accept",fn:function(text, record){return _c('span',{},[(record.status != 2 && record.acceptStatus == 0)?[_c('a',{on:{"click":function($event){return _vm.handleAccept(record.meetingId, 1)}}},[_vm._v(_vm._s(_vm.$t("user.meeting.accept")))]),_vm._v(" | "),_c('a',{on:{"click":function($event){return _vm.handleAccept(record.meetingId, -1)}}},[_vm._v(_vm._s(_vm.$t("user.meeting.decline")))])]:[_vm._v(" "+_vm._s(record.acceptStatusText)+" ")]],2)}},{key:"check",fn:function(text, record){return _c('span',{},[_c('router-link',{attrs:{"to":{
                  name: 'PurchaserInfo',
                  params: { id: record.purchaserId, userId: _vm.userId },
                }}},[_vm._v(_vm._s(_vm.$t("user.check")))])],1)}},{key:"chat",fn:function(text, record){return _c('span',{},[_c('router-link',{attrs:{"to":{
                  name: 'Talking',
                  params: { adminId: record.purchaserUserId },
                }}},[_vm._v(_vm._s(_vm.$t("user.send-message")))])],1)}},{key:"start",fn:function(text, record){return _c('span',{},[(record.status != 2 && record.acceptStatus == 1)?_c('a',[_vm._v(_vm._s(_vm.$t("user.meeting.startup")))]):_c('a',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s(_vm.$t("user.meeting.startup")))])])}}])})],1)],1)])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }